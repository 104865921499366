import { instance } from '@/utils/http.js'

// 获取启用学校
export const getStartSchool = function () {
  return instance({
    url: '/api/v2/public/enabled_school',
    method: 'get'
  })
}

