<template>
  <div>
    <el-form label-width="80px"
             :model="form">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="来源编号:">
            <el-input v-model="form.source_id"
                      placeholder="请输入"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="来源名称:">
            <el-input v-model="form.keyword"
                      placeholder="请输入"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       clearable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="年级:">
            <el-select style="width:100%"
                       clearable
                       v-model="form.grade_id"
                       placeholder="请选择年级">
              <el-option v-for="option in gradeList"
                         :key="option.grade_id"
                         :label="option.grade_name"
                         :value="option.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item rop="is_famous"
                        label-width="110px"
                        label="是否名校强市:">
            <el-select clearable
                       style="width:100%"
                       v-model="form.is_famous">
              <el-option label="是"
                         value="1"></el-option>
              <el-option label="否"
                         value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="是否中考真题:"
                        label-width="110px"
                        rop="is_true_paper">
            <el-select clearable
                       style="width:100%"
                       v-model="form.is_true_paper">
              <el-option label="是"
                         value="1"></el-option>
              <el-option label="否"
                         value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="是否备考:"
                        label-width="110px"
                        rop="is_for_senior">
            <el-select clearable
                       style="width:100%"
                       v-model="form.is_for_senior">
              <el-option label="是"
                         value="1"></el-option>
              <el-option label="否"
                         value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <div class="butleft">
            <el-button type="primary"
                       @click="search">查询</el-button>
            <el-button type="primary"
                       @click="add">新增</el-button>
            <el-button type="primary"
                       @click="toImportSource">导入</el-button>
          </div>
        </el-col>

      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'source_id', align: 'center', label: '编号', width: '100' },
        { prop: 'source_name', align: 'center', label: '来源名称', width: '' },
        // { prop: 'subject_id', align: 'center', label: '科目编号', width: '' },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '100' },
        { prop: 'paper_district_name', align: 'center', label: '试卷地区', width: '150' },
        { prop: 'grade_name', align: 'center', label: '年级', width: '120' },
        { prop: 'is_famous', align: 'center', label: '是否名校强市', width: '120', isStatus: true },
        { prop: 'is_true_paper', align: 'center', label: '是否中考真题', width: '120', isStatus: true },
        { prop: 'is_for_senior', align: 'center', label: '是否备考', width: '120', isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      tableData: [],
      subjectList: [],
      gradeList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        subject_id: '',
        keyword: '',
        source_id: '',
        grade_id: '',
        is_famous: '',
        is_true_paper: '',
        is_for_senior: ''
      },
      pageName: 'slcz_papersource_admin',
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
    this.getSubjectList()
    this.getgradeList()
  },
  methods: {
    getSubjectList () {
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    getClass (prop, value) {
      if (prop == 'is_famous') {
        return value ? 'pass' : 'notpass'
      }
      if (prop == 'is_true_paper') {
        return value ? 'pass' : 'notpass'
      }
      if (prop == 'is_for_senior') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'is_famous') {
        return value ? '是' : '否'
      }
      if (prop == 'is_true_paper') {
        return value ? '是' : '否'
      }
      if (prop == 'is_for_senior') {
        return value ? '是' : '否'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.size = limit
      this.page = page
      let params = {
        keyword: this.form.keyword,
        subject_id: this.form.subject_id,
        source_id: this.form.source_id,
        grade_id: this.form.grade_id,
        is_famous: this.form.is_famous,
        is_true_paper: this.form.is_true_paper,
        is_for_senior: this.form.is_for_senior,
        page,
        limit
      }
      this.$http({
        url: '/api/v2/paper/source',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
        if (this.tableData.length == 0 && this.totalElements != 0 && Math.ceil(this.totalElements / this.size) != this.page) {
          let newPage = Math.ceil(this.totalElements / this.size)
          this.initData(newPage, this.size)
        }
      })
    },
    getgradeList () {
      this.$http({
        url: '/api/v2/public/grade_lst',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    editRow (row) {
      let form = {
        source_id: row.source_id,
        source_name: row.source_name,
        subject_id: row.subject_id,
        paper_district_id: row.paper_district_id,
        grade_id: row.grade_id,
        is_famous: row.is_famous,
        is_true_paper: row.is_true_paper,
        is_for_senior: row.is_for_senior,
        school_id: row.school_ids ? Number(row.school_ids[0]) : '',
        school_group_id: row.school_group_id ? row.school_group_id : ''
      }
      this.$refs.FormRef.getList()
      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    add () {
      let form = {
        source_name: '',
        subject_id: '',
        paper_district_id: '',
        grade_id: '',
        is_famous: 0,
        is_true_paper: 0,
        is_for_senior: 0,
        school_id: 1,
        school_group_id: ''
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.getList()
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    toImportSource () {
      let file
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.et' }).then(res => {
        file = res
        let formData = new FormData()
        formData.append('file', file)
        formData.append('sign', this.$getMd5Form(file))
        this.$http({
          url: 'api/v2/paper/import_source',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.form = {
            subject_id: '',
            keyword: ''
          }
          let totalLast = res.data.number
          let page = Math.ceil(totalLast / this.size)
          let num = totalLast % this.size
          if (num == 0) {
            page = page + 1
            this.totalElements = totalLast + 1
          } else {
            this.totalElements = totalLast
          }
          this.$refs.pagination.handleCurrentChange(page)
        })
      })
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((() => {
        this.$http({
          url: '/api/v2/paper/source_del',
          method: 'POST',
          data: {
            source_id: row.source_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.refresh()
        })
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  margin-bottom: 5px;
}
::v-deep .content .el-form-item__content {
  margin-left: 106px !important;
  box-sizing: border-box;
}
.butleft {
  margin-left: 80px;
  box-sizing: border-box;
}
</style>