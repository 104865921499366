import { instance } from '@/utils/http.js'

// 获取学校分组
export const getSchoolGroup = function (params) {
  return instance({
    url: '/api/v2/paper/school_group_list',
    method: 'get',
    params
  })
}

// 创建分组
export const createGroup = function (data) {
  return instance({
    url: '/api/v2/paper/school_group_create',
    method: 'post',
    data
  })
}

export const getGroupInfo = function (params) {
  return instance({
    url: '/api/v2/paper/school_group_detail',
    method: 'get',
    params
  })
}
