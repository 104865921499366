<template>
  <div>
    <el-dialog :title="text + '试卷来源'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="100px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="来源名称"
                          prop="source_name">
              <el-input placeholder="输入如：全国I卷"
                        v-model="form.source_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="所属科目"
                          prop="subject_id">
              <el-select style="width:100%"
                         v-model="form.subject_id"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="试卷地区"
                          prop="paper_district_id">
              <el-select style="width:100%"
                         v-model="form.paper_district_id"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in disList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="试卷年级"
                          prop="grade_id">
              <el-select style="width:100%"
                         v-model="form.grade_id"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="学校"
                          prop="school_id">
              <el-select v-model="form.school_id"
                         style="width:100%"
                         clearable
                         filterable
                         @change="clear('school_group_id')"
                         placeholder="请选择">
                <el-option v-for="item in schoolList"
                           :key="item.school_id"
                           :label="item.school_name"
                           :value="item.school_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="分组">
              <el-select v-model="form.school_group_id"
                         style="width:100%"
                         clearable
                         filterable
                         @change="clear('school_id')"
                         placeholder="请选择">
                <el-option v-for="item in groupList"
                           :key="item.school_group_id"
                           :label="item.group_name"
                           :value="item.school_group_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="是否名校强市"
                          prop="is_famous">
              <el-radio-group v-model="form.is_famous">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否中考真题"
                          prop="is_famous">
              <el-radio-group v-model="form.is_true_paper">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否备考"
                          prop="is_famous">
              <el-radio-group v-model="form.is_for_senior">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStartSchool } from '@/api/school.js'
import { getSchoolGroup } from '@/api/school_group.js'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      subjectList: [],
      disList: [],
      gradeList: [],
      groupList: [],
      rules: {
        source_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        is_famous: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        is_famous: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        school_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      text: '',
      schoolList: []
    }
  },
  mounted () {
    this.initList()
  },
  methods: {
    clear (str) {
      this.form[str] = ''
    },
    async initList () {
      let params = {
        page: 1,
        limit: 10000
      }
      const { data } = await getSchoolGroup(params)
      this.groupList = data.list

      const { data: data1 } = await getStartSchool()
      this.schoolList = data1.list
    },
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        if (!form.school_group_id) {
          form.school_group_id = 0
        }
        this.$http({
          url: '/api/v2/paper/source_add',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.refresh()
        })
      })
    },
    getList () {
      this.getSubjectList()
      this.getDisList()
      this.getGradeList()
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        console.log('res', res)
        this.subjectList = res.data.list
      })
    },
    getDisList () {
      this.$http({
        url: '/api/v2/public/paper_district',
        method: 'get'
      }).then(res => {
        this.disList = res.data.list
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v2/public/grade_lst',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>